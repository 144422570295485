import { Box, Heading, Stack, Text, Button } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React from 'react';
import CartBar from '../components/cart/CartBar';
import NavigationBar from '../components/NavigationBar';
import { ScrollRestoration, useNavigate } from 'react-router-dom';
import FooterThreeColumn from '../components/FooterThreeColumn';
import { PageTitle } from '../components/internal';

const OrderConfirmationPage = React.forwardRef<HTMLDivElement, unknown>((props, ref) => {
	const navigate = useNavigate();
	return (
		<Box textAlign="center" fontSize="xl" ref={ref}>
			{/* TODO Prefix Support? */}
			<PageTitle title="Order Confirmation" />
			<ScrollRestoration />
			<CartBar />
			<NavigationBar />
			{/* TODO Refactor to Own Component */}
			<Box
				maxW="7xl"
				mx="auto"
				px={{ base: '4', md: '8', lg: '12' }}
				py={{ base: '6', md: '8', lg: '12' }}
			>
				<Stack spacing={{ base: '8', md: '12' }}>
					<Heading fontSize="2xl" fontWeight="extrabold">
						Order Confirmation
					</Heading>
					<Text>Thank you for your purchase!</Text>
					<Text>Your order number is: #ABC-45668952</Text>
					<Text>
						We will email you an order confirmation with details and tracking info, if applicable.
					</Text>
					<Button
						colorScheme="blue"
						size="md"
						onClick={() => {
							// navigate('/rewards');
							window.print();
						}}
					>
						Print Confirmation
					</Button>
					<Button
						colorScheme="blue"
						size="md"
						onClick={() => {
							navigate('/rewards');
						}}
					>
						Continue Shopping
					</Button>
				</Stack>
			</Box>
			<FooterThreeColumn />
		</Box>
	);
});

export const AnimatedOrderConfirmationPage = motion(
	React.forwardRef<HTMLDivElement, unknown>((props, ref) => <OrderConfirmationPage ref={ref} />),
);

export default OrderConfirmationPage;
