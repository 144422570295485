import { useEffect, useState } from 'react';
import { useApiManager } from '../../../domain/hooks';
import { IGetRewardsRequestParameters } from '../../../domain/models';
import {
	FormLabel,
	Input,
	InputGroup,
	InputRightElement,
	Popover,
	Radio,
	Skeleton,
	Stack,
	useColorModeValue,
} from '@chakra-ui/react';
import { ProductFilterPopoverButton, ProductFilterPopoverContent } from './ProductFilterPopover';
import { FiSearch } from 'react-icons/fi';

interface IFilterProps {
	getRewardsParams: IGetRewardsRequestParameters;
	setGetRewardsParams: React.Dispatch<React.SetStateAction<IGetRewardsRequestParameters>>;
	multiSelect?: boolean;
	label?: string;
	hideLabel?: boolean;
	showSearch?: boolean;
}

interface Option {
	label: string;
	value: string;
}
// TODO - Implement Multi
const ProductCategoryFilter: React.FC<IFilterProps> = ({
	hideLabel,
	label,
	showSearch,
	getRewardsParams,
	setGetRewardsParams,
}) => {
	const { useGetTags } = useApiManager();
	const { data, isLoading } = useGetTags();
	const [options, setOptions] = useState<Option[]>([]);
	const colorValue = useColorModeValue('secondary.500', 'secondary.200');
	const [selectedValue, setSelectedValue] = useState<string | string[] | undefined>();

	useEffect(() => {
		const newOpts: Option[] = [];
		data?.tags?.forEach((v) => {
			newOpts.push({ label: v.voucherTagName ?? 'UNKNOWN', value: v.voucherTagCode ?? 'NONE' });
		});
		setOptions(newOpts);
		// eslint-disable-next-line
	}, [data]);

	return (
		<Popover placement="bottom-start">
			<ProductFilterPopoverButton label="Category" />
			<ProductFilterPopoverContent
				onClickApply={() => {
					setGetRewardsParams({
						...getRewardsParams,
						Tag: selectedValue as string | undefined,
					});
				}}
				onClickCancel={() => {
					setGetRewardsParams({
						...getRewardsParams,
						Tag: undefined,
					});
				}}
				showReset
				onClickReset={() => {
					// console.log('Reset Clicked');
					setGetRewardsParams({
						...getRewardsParams,
						Tag: undefined,
					});
					setSelectedValue(undefined);
				}}
			>
				<Skeleton isLoaded={!isLoading}>
					<Stack as="fieldset" spacing={'2'}>
						{!hideLabel && (
							<FormLabel fontWeight="semibold" as="legend" mb="0">
								{label}
							</FormLabel>
						)}
						{showSearch && (
							<InputGroup size="md" pb="1">
								<Input placeholder="Search..." rounded="md" focusBorderColor={colorValue} />
								<InputRightElement pointerEvents="none" color="gray.400" fontSize="lg">
									<FiSearch />
								</InputRightElement>
							</InputGroup>
						)}
						<Stack>
							{options.map((option) => (
								<Radio
									key={option.value}
									value={option.value}
									colorScheme="secondary"
									isChecked={option.value === selectedValue}
									onChange={(e) => {
										// console.log(e.target.value);
										setSelectedValue(e.target.value);
									}}
								>
									{option.label}
								</Radio>
							))}
						</Stack>
					</Stack>
				</Skeleton>
			</ProductFilterPopoverContent>
		</Popover>
	);
};
export default ProductCategoryFilter;
